import React, { useState, useEffect } from 'react';
import FileUpload from './FileUpload'; // Ensure the correct path is used
import Modal from 'react-modal';

function App() {
  const [mode, setMode] = useState('marking'); // Can be 'marking' or 'proofreading'
  const [results, setResults] = useState([]); // To store the results
  const [loading, setLoading] = useState(false); // To control the progress bar visibility
  const [modalIsOpen, setModalIsOpen] = useState(false); // To handle modal visibility
  const [selectedResult, setSelectedResult] = useState(null); // For the selected section result
  const [finalGrade, setFinalGrade] = useState(null); // To store final grade information

  // Ensure Modal is set to the root element for accessibility
  Modal.setAppElement('#root'); // or your main element

  // Log and update results when the results array is updated
  useEffect(() => {
    console.log('Results updated:', results);

    if (mode === 'marking') {
      console.group('Marking Mode');
      console.log('Marking mode:', results);
      if (Array.isArray(results.analysisAndGrading)) {
        setResults(results.analysisAndGrading);
        setFinalGrade(results.finalGradeSummary);
        console.log('Processed marking results:', results.analysisAndGrading);
        console.log('Final grade:', finalGrade);
      }
      console.groupEnd();
    } else if (mode === 'proofreading') {
      console.group('Proofreading Mode');
      console.log('Proofreading mode, raw results:', results);
      if (typeof results === 'string') {
        const splitResults = results.split("\n\n").map((section) => {
          const parts = section.split("\n");
          return {
            grammatical_errors: parts[0]?.replace("Grammatical Errors:", "").trim() || "None",
            suggested_improvements: parts[1]?.replace("Suggested Improvements:", "").trim() || "None",
            feedback: parts[2]?.replace("Feedback:", "").trim() || "None",
          };
        });
        setResults(splitResults);
        console.log('Processed proofreading results:', splitResults);
      }
      console.groupEnd();
    }
  }, [results, mode]);

  // Log when the mode switches
  useEffect(() => {
    console.log(`Switched to mode: ${mode}`);
  }, [mode]);

  // Log when the loading state changes
  useEffect(() => {
    if (loading) {
      console.log('Loading spinner active, processing...');
    } else {
      console.log('Loading spinner inactive');
    }
  }, [loading]);

  const openModal = (result) => {
    console.log('Opening modal with result:', result);
    setSelectedResult(result);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    console.log('Closing modal');
    setModalIsOpen(false);
    setSelectedResult(null);
  };

  return (
    <div className="App">
      {/* Add the logo */}
      <div className="logo">
        <img
          src="https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vk_edu/vkEdu1.png"
          alt="VK Edu Logo"
        />
      </div>
      <h1>Document Upload and Processing</h1>

      {/* Tab navigation */}
      <div className="mode-buttons">
        <button
          onClick={() => setMode('marking')}
          className={mode === 'marking' ? 'active' : ''}
        >
          Marking
        </button>
        <button
          onClick={() => setMode('proofreading')}
          className={mode === 'proofreading' ? 'active' : ''}
        >
          Proofreading
        </button>
      </div>

      {/* Render FileUpload based on the mode */}
      {mode === 'marking' ? (
        <>
          <h2>Upload Your Document for Marking</h2>
          <FileUpload mode="marking" setResults={setResults} setLoading={setLoading} />
        </>
      ) : (
        <>
          <h2>Upload Your Document for Proofreading</h2>
          <FileUpload mode="proofreading" setResults={setResults} setLoading={setLoading} />
        </>
      )}

      {/* Show loading spinner while waiting for results */}
      {loading && (
        <div className="loading-container">
          <div className="spinner"></div>
          <p>Processing your document, please wait...</p>
        </div>
      )}

      {/* Results section for marking */}
      {mode === 'marking' && Array.isArray(results) && results.length > 0 && (
        <div className="result-section">
          <table className="results-table">
            <thead>
              <tr>
                <th>Section</th>
                <th>Summary</th>
                <th>Final Judgment</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {results.map((result, index) => {
                console.log(`Rendering result for section ${index + 1}:`, result);
                return (
                  <tr key={index}>
                    <td>Section {index + 1}</td>
                    <td>{result.summary || 'No summary available'}</td>
                    <td>{result.final_judgment || 'No final judgment available'}</td>
                    <td>
                      <button onClick={() => openModal(result)}>View Details</button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Display the final grade summary */}
          {finalGrade && (
            <div className="final-grade-summary">
              <h3>Final Grade Summary</h3>
              <p><strong>Total Points:</strong> {finalGrade.points}</p>
              <p><strong>Percentage:</strong> {finalGrade.percentage}%</p>
              <p><strong>Grade:</strong> {finalGrade.gradeLetter}</p>
              <p><strong>Number of Sections:</strong> {results.length}</p>
            </div>
          )}
        </div>
      )}

      {/* Results section for proofreading */}
      {mode === 'proofreading' && Array.isArray(results) && results.length > 0 && (
        <div className="result-section">
          <h3>Proofreading Results</h3>
          <table className="results-table">
            <thead>
              <tr>
                <th>Section</th>
                <th>Grammatical Errors</th>
                <th>Suggested Improvements</th>
                <th>Feedback</th>
              </tr>
            </thead>
            <tbody>
              {results.map((result, index) => {
                console.log(`Rendering proofreading result for section ${index + 1}:`, result);
                return (
                  <tr key={index}>
                    <td>Section {index + 1}</td>
                    <td>{result.grammatical_errors || 'None'}</td>
                    <td>{result.suggested_improvements || 'None'}</td>
                    <td>{result.feedback || 'None'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {/* Modal for detailed result */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Detailed Section Result"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Section Details</h2>
        {selectedResult && (
          <div className="modal-content">
            {mode === 'marking' ? (
              <>
                {/* Marking Details */}
                <p><strong>Summary:</strong> {selectedResult.summary || 'No summary available'}</p>
                <p><strong>Analysis:</strong> {selectedResult.analysis || 'No analysis available'}</p>
                <p><strong>Grading:</strong> {selectedResult.grading || 'No grading available'}</p>
                <p><strong>Final Judgment:</strong> {selectedResult.final_judgment || 'No final judgment available'}</p>
              </>
            ) : (
              <>
                {/* Proofreading Details */}
                <p><strong>Grammatical Errors:</strong> {selectedResult.grammatical_errors || 'None'}</p>
                <p><strong>Suggested Improvements:</strong> {selectedResult.suggested_improvements || 'None'}</p>
                <p><strong>Feedback:</strong> {selectedResult.feedback || 'None'}</p>
              </>
            )}
          </div>
        )}
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
}

export default App;