import React, { useState, useEffect } from 'react';
import axios from 'axios';

const FileUpload = ({ mode, setResults, setLoading }) => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [strictness, setStrictness] = useState('medium');
  const [style, setStyle] = useState('APA'); // Default style for proofreading
  const [cancelSource, setCancelSource] = useState(null); // For canceling axios requests

  // Cleanup and manage state when the component unmounts
  useEffect(() => {
    let isMounted = true;
  
    return () => {
      isMounted = false; // Clean up to avoid memory leaks
      if (cancelSource) {
        cancelSource.cancel("Operation canceled by the user."); // Cancel request if any
      }
    };
  }, [cancelSource]); // Add cancelSource as a dependency to properly handle it

  // Function to handle file change
  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Function to handle file upload
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage("Please select a file to upload.");
      return;
    }

    setLoading(true); // Set loading state to true
    setMessage(''); // Clear any previous messages

    const formData = new FormData();
    formData.append('document', file);

    // Include strictness level only for marking mode
    if (mode === 'marking') {
      formData.append('strictness', strictness);
    }

    // Include style guide only for proofreading mode
    if (mode === 'proofreading') {
      formData.append('style', style); // Pass the selected style
    }

    const cancelTokenSource = axios.CancelToken.source();
    setCancelSource(cancelTokenSource);

    try {
      const uploadUrl = mode === 'marking'
        ? 'http://localhost:3015/upload-mark' // For marking
        : 'http://localhost:3015/upload-proofread'; // For proofreading

      // Post request to upload file
      const response = await axios.post(uploadUrl, formData, {
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentage); // Set progress percentage
        },
        cancelToken: cancelTokenSource.token,
      });

      // Assuming document content is received in response
      if (response.data.result) {
        console.log('Received result from backend:', response.data.result);
        setResults(response.data.result); // Set the results directly from the response
        setMessage('File uploaded and processed successfully.');
      } else {
        setMessage('No result received from the server.');
      }

    } catch (err) {
      if (axios.isCancel(err)) {
        setMessage('File upload canceled.');
      } else {
        setMessage('Error uploading file. Please try again later.');
      }
    } finally {
      setLoading(false); // Set loading state to false
      setUploadProgress(0); // Reset upload progress
    }
  };

  return (
    <div className="upload-container">
      <h2>{mode === 'marking' ? 'Upload Document for Marking' : 'Upload Document for Proofreading'}</h2>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label>Select File:</label>
          <input type="file" onChange={onFileChange} required />
        </div>

        {/* Conditionally render strictness level for marking mode only */}
        {mode === 'marking' && (
          <div className="form-group">
            <label>Select Strictness Level:</label>
            <select value={strictness} onChange={(e) => setStrictness(e.target.value)}>
              <option value="easy">Easy</option>
              <option value="medium">Medium</option>
              <option value="strict">Strict</option>
            </select>
          </div>
        )}

        {/* Conditionally render style guide selection for proofreading mode only */}
        {mode === 'proofreading' && (
          <div className="form-group">
            <label>Select Style Guide:</label>
            <select value={style} onChange={(e) => setStyle(e.target.value)}>
              <option value="APA">APA</option>
              <option value="MLA">MLA</option>
              <option value="Chicago">Chicago</option>
              <option value="SBL">SBL</option>
              <option value="Harvard">Harvard</option>
              <option value="IEEE">IEEE</option>
            </select>
          </div>
        )}

        <div className="form-group">
          <button type="submit">Upload</button>
        </div>

        {uploadProgress > 0 && <progress value={uploadProgress} max="100">{uploadProgress}%</progress>}

        {message && <div className="message">{message}</div>}
      </form>
    </div>
  );
};

export default FileUpload;
